import React, { useState } from 'react';

import { makeStyles, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { logout } from '~/services/magic';

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
  },
  headerProfile: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
  },
  logoutIcon: {
    width: '16px',
    height: '9px',
    marginLeft: '10px',
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
  button: {},
}));

const Header = ({ children }) => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);

  if (!user) return null;

  const role = user.role.slice(0, 1).toUpperCase() + user.role.slice(1);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logout();
    } catch (err) {
      console.error(err);
    } finally {
      window.location.href = window.location.origin;
      setLoading(false);
    }
  };

  return (
    <header className={classes.header}>
      <div></div>

      <div className={classes.headerProfile}>
        <div style={{ textAlign: 'right' }}>
          <div>{role}</div>
          <div style={{ fontWeight: 400 }}>{user.email}</div>
        </div>
        <img
          alt="Profile"
          style={{ width: '20px', height: '23px', margin: '0 20px' }}
          src="/static/icons/profile.svg"
        />
        <Button
          color="primary"
          disabled={loading}
          type="button"
          variant="contained"
          onClick={handleLogout}
          size="small"
        >
          {loading ? 'Logging out' : 'Log out'}
        </Button>
      </div>
    </header>
  );
};

export default Header;
