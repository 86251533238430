import React, { useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CRUDTable, {
  Fields,
  Field,
  CreateForm,
  UpdateForm,
  DeleteForm,
} from 'react-crud-table';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Page from '~/components/shared/Page';
import { fetchCompanies } from '~/store/companiesList';
import {
  getSorter,
  validateEmail,
  validateEmailAndId,
} from '~/views/utils/utils';
import CompanySelector from '~/views/components/CompanySelector';
import * as clients from '../../../store/clientsList';

const AdminsView = () => {
  const companiesList = useSelector(state => state.companiesList);
  const clientsList = useSelector(state => state.clientsList);
  const companies = [...new Set(clientsList.map(client => client.companyName))];
  const groupedClients = companies.reduce(
    (acc, company) => ({
      ...acc,
      [company]: clientsList.filter(client => client.companyName === company),
    }),
    {},
  );

  const dispatch = useDispatch();

  const [companyFilter, setCompanyFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');

  const [sort, setSort] = useState({ direction: 'ascending', field: 'id' });

  const filteredGroupedClientsList = Object.entries(groupedClients)
    .filter(([company]) => (companyFilter ? company === companyFilter : true))
    .map(([company, clients]) => {
      return [
        company,
        emailFilter
          ? clients.filter(c => c.email.includes(emailFilter))
          : clients,
      ];
    })
    .filter(([, clients]) => clients.length > 0)
    .flatMap(([, clients]) => clients);

  if (filteredGroupedClientsList.length === 0 && companyFilter) {
    setCompanyFilter('');
  }

  useLayoutEffect(() => {
    dispatch(fetchCompanies()).then(res => res);
    dispatch(clients.fetchClients()).then(res => res);
  }, []);

  function createClient(client) {
    return dispatch(clients.createClient(client))
      .then(() => toast.success('Client has been created successfully'))
      .catch(({ message }) => toast.error(message));
  }

  function changeClient(client) {
    return dispatch(clients.changeClient(client))
      .then(() => toast.success('Client has been updated successfully'))
      .catch(({ message }) => toast.error(message));
  }

  function deleteClient(clientId) {
    return dispatch(clients.deleteClient(clientId))
      .then(() => toast.success('Client has been deleted successfully'))
      .catch(({ message }) => toast.error(message));
  }

  if (!companiesList.length) return null;

  return (
    <Page title="Clients">
      <ToastContainer />
      <div className="mx-auto container" style={{ maxWidth: '1400px' }}>
        <div>
          <div className="ml-3 mt-2" style={{ float: 'right' }}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Filter by Company: </label>
            <select
              className="focus:outline-none rounded-lg px-3 py-1"
              style={{ backgroundColor: 'lightgrey' }}
              onChange={e => setCompanyFilter(e.target.value)}
              value={companyFilter}
            >
              <option value="">All companies</option>
              {companies.map(company => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>
          <div
            className="ml-3 mt-2"
            style={{ position: 'relative', float: 'right' }}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Filter by Email: </label>
            <input
              className="focus:outline-none rounded-lg px-3 py-1"
              onChange={e => setEmailFilter(e.target.value)}
              value={emailFilter}
              style={{ paddingRight: '2em', backgroundColor: 'lightgrey' }}
            />
            {emailFilter.length > 0 && (
              <button
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '45%',
                  right: '5px',
                  transform: 'translateY(-50%)',
                }}
                onClick={() => setEmailFilter('')}
              >
                &times;
              </button>
            )}
          </div>
        </div>

        <CRUDTable
          onChange={e => setSort(e.sort)}
          caption="Clients"
          items={Array.from(filteredGroupedClientsList).sort(getSorter(sort))}
          // fetchItems={payload => {
          //   return Promise.resolve(Array.from(filteredGroupedClientsList).sort(getSorter(payload.sort)))
          // }}
        >
          <Fields>
            <Field name="id" label="Id" hideInCreateForm hideInUpdateForm />
            <Field
              name="companyName"
              label="Company"
              render={props => (
                <CompanySelector {...props} companiesList={companiesList} />
              )}
            />
            <Field name="email" label="Email" />
          </Fields>

          <CreateForm
            title="Create Client"
            message="Create a new client!"
            trigger="Create Client"
            initialValues={{ companyName: companiesList[0] }}
            onSubmit={client => createClient(client).then(() => client)}
            submitText="Create client"
            validate={values => validateEmail(values)}
          />

          <UpdateForm
            title="Client update process"
            message="Update user"
            trigger="Update"
            onSubmit={client => changeClient(client).then(() => client)}
            submitText="Update"
            validate={values => validateEmailAndId(values)}
          />

          <DeleteForm
            title="Delete"
            message="Are you sure you want to delete this client?"
            trigger="Delete"
            onSubmit={client => deleteClient(client.id).then(() => client)}
            submitText="Delete"
            validate={values => {
              const errors = {};
              if (!values.id) {
                errors.id = 'Please, provide id';
              }
              return errors;
            }}
          />
        </CRUDTable>
      </div>
    </Page>
  );
};

export default AdminsView;
