import { createSlice } from '@reduxjs/toolkit';

import * as clientApi from '../services/clientApi';

export const companiesSlice = createSlice({
  name: 'companiesList',
  initialState: [],
  reducers: {
    set: (_, action) => action.payload,
  },
});

export const { set } = companiesSlice.actions;

export const fetchCompanies = () => dispatch => {
  return clientApi.getCompanies().then(companies => dispatch(set(companies)));
};

export default companiesSlice.reducer;
