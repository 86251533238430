import { createSlice } from '@reduxjs/toolkit';

import * as clientApi from '../services/clientApi';

export const clientsSlice = createSlice({
  name: 'clientsList',
  initialState: [],
  reducers: {
    set: (_, action) => action.payload,
    add: (clients, action) => [...clients, action.payload],
    remove: (clients, action) => clients.filter(c => c.id !== action.payload),
    change: (clients, { payload: { id, ...client } }) =>
      clients.map(c => (c.id !== id ? c : { ...c, ...client })),
  },
});

export const { set, add, remove, change } = clientsSlice.actions;

export const fetchClients = () => dispatch => {
  return clientApi.getClients().then(clients => dispatch(set(clients)));
};

export const createClient = client => dispatch => {
  return clientApi.createClient(client).then(c => dispatch(add(c)));
};

export const changeClient = client => dispatch => {
  return clientApi.changeClient(client).then(_ => dispatch(change(client)));
};

export const deleteClient = clientId => dispatch => {
  return clientApi.deleteClient(clientId).then(_ => dispatch(remove(clientId)));
};

export default clientsSlice.reducer;
