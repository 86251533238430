import React from 'react';

const CompanySelector = ({ field, form, companiesList = [] }) => {
  const handleChange = e => {
    form.setFieldValue('companyName', e.target.value);
  };

  return (
    <select name="companyName" value={field.value} onChange={handleChange}>
      {companiesList.map(company => (
        <option key={company} value={company}>
          {company}
        </option>
      ))}
    </select>
  );
};

export default CompanySelector;
