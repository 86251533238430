import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Header from './components/header';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto',
    padding: '32px 47px 27px 36px',
    margin: '0 auto',
    maxWidth: '1580px',
    // paddingTop: 64,
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256,
    // },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',
  },
}));

function MainLayout() {
  const classes = useStyles();
  const location = useLocation();

  const auth = useSelector(state => state.auth);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Header />
            <div
              className="flex justify-center mx-auto mb-12 h-10"
              style={{ width: '600px' }}
            >
              <Link to="/clients">
                <button
                  className="focus:outline-none px-2 py-1 rounded-lg text-white font-bold"
                  style={{ background: '#171717' }}
                >
                  Clients
                </button>
              </Link>
              {auth.user && auth.user.role === 'superadmin' && (
                <Link to="/admins">
                  <button
                    className="focus:outline-none px-2 py-1 rounded-lg text-white font-bold ml-2"
                    style={{ background: '#171717' }}
                  >
                    Admins
                  </button>
                </Link>
              )}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
