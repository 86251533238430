import { createSlice } from '@reduxjs/toolkit';

import * as clientApi from '../services/clientApi';

export const adminsSlice = createSlice({
  name: 'adminsList',
  initialState: [],
  reducers: {
    set: (_, action) => action.payload,
    add: (admins, action) => [...admins, action.payload],
    remove: (admins, action) => admins.filter(c => c.id !== action.payload),
    change: (admins, { payload: { id, ...admin } }) =>
      admins.map(a => (a.id !== id ? a : { ...a, ...admin })),
  },
});

export const { set, add, remove, change } = adminsSlice.actions;

export const fetchAdmins = () => dispatch => {
  return clientApi.getAdmins().then(admins => dispatch(set(admins)));
};

export const createAdmin = admin => dispatch => {
  return clientApi.createAdmin(admin).then(admin => dispatch(add(admin)));
};

export const changeAdmin = admin => dispatch => {
  return clientApi.changeAdmin(admin).then(_ => dispatch(change(admin)));
};

export const deleteAdmin = adminId => dispatch => {
  return clientApi.deleteAdmin(adminId).then(_ => dispatch(remove(adminId)));
};

export default adminsSlice.reducer;
