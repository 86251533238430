import { combineReducers } from 'redux';
import authReducer from './auth';
import clientsListReducer from './clientsList';
import adminsListReducer from './adminsList';
import companiesListReducer from './companiesList';

export default combineReducers({
  auth: authReducer,
  clientsList: clientsListReducer,
  adminsList: adminsListReducer,
  companiesList: companiesListReducer,
});
