import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CRUDTable, {
  Fields,
  Field,
  CreateForm,
  UpdateForm,
  DeleteForm,
} from 'react-crud-table';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Page from '~/components/shared/Page';
import { fetchCompanies } from '~/store/companiesList';
import {
  getSorter,
  validateEmail,
  validateEmailAndId,
} from '~/views/utils/utils';
import * as admins from '../../../store/adminsList';

const AdminsView = () => {
  const navigate = useNavigate();

  const auth = useSelector(state => state.auth);
  const companiesList = useSelector(state => state.companiesList);
  const adminsList = useSelector(state => state.adminsList);
  const companies = [...new Set(adminsList.map(admin => admin.companyName))];
  const groupedAdmins = companies.reduce(
    (acc, company) => ({
      ...acc,
      [company]: adminsList.filter(admin => admin.companyName === company),
    }),
    {},
  );

  useEffect(() => {
    if (!auth.user || auth.user.role !== 'superadmin') {
      return navigate('/clients', { replace: true });
    }
  }, [auth, navigate]);

  const dispatch = useDispatch();

  const [emailFilter, setEmailFilter] = useState('');

  const [sort, setSort] = useState({ direction: 'ascending', field: 'id' });

  const filteredGroupedAdminsList = Object.entries(groupedAdmins)
    .map(([company, admins]) => {
      return [
        company,
        emailFilter
          ? admins.filter(a => a.email.includes(emailFilter))
          : admins,
      ];
    })
    .filter(([, admins]) => admins.length > 0)
    .flatMap(([, admins]) => admins);

  useLayoutEffect(() => {
    dispatch(fetchCompanies()).then(res => res);
    dispatch(admins.fetchAdmins()).then(res => res);
  }, []);

  function createAdmin(admin) {
    return dispatch(admins.createAdmin(admin))
      .then(() => toast.success('Admin has been created successfully'))
      .catch(({ message }) => toast.error(message));
  }

  function changeAdmin(admin) {
    return dispatch(admins.changeAdmin(admin))
      .then(() => toast.success('Admin has been updated successfully'))
      .catch(({ message }) => toast.error(message));
  }

  function deleteAdmin(adminId) {
    return dispatch(admins.deleteAdmin(adminId))
      .then(() => toast.success('Admin has been deleted successfully'))
      .catch(({ message }) => toast.error(message));
  }

  if (!companiesList.length) return null;

  return (
    <Page title="Admins">
      <ToastContainer />
      <div className="mx-auto container" style={{ maxWidth: '1400px' }}>
        <div style={{ float: 'right' }}>
          <div className="ml-3 mt-2" style={{ position: 'relative' }}>
            <label>Filter by Email: </label>
            <input
              className="focus:outline-none rounded-lg px-3 py-1"
              onChange={e => setEmailFilter(e.target.value)}
              value={emailFilter}
              style={{ paddingRight: '2em', backgroundColor: 'lightgrey' }}
            />
            {emailFilter.length > 0 && (
              <button
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '45%',
                  right: '5px',
                  transform: 'translateY(-50%)',
                }}
                onClick={() => setEmailFilter('')}
              >
                &times;
              </button>
            )}
          </div>
        </div>

        <CRUDTable
          onChange={e => setSort(e.sort)}
          caption="Administrators"
          items={Array.from(filteredGroupedAdminsList).sort(getSorter(sort))}
        >
          <Fields>
            <Field name="id" label="Id" hideInCreateForm hideInUpdateForm />
            <Field
              name="companyName"
              label="Company"
              render={() => <p style={{ fontWeight: 'bold' }}>The View</p>}
            />
            <Field name="email" label="Email" />
            <Field
              name="role"
              label="Role"
              render={({ field, form }) => {
                return (
                  <select
                    name="role"
                    value={field.value}
                    onChange={e => {
                      form.setFieldValue('role', e.target.value);
                    }}
                  >
                    <option value="admin">Admin</option>
                    <option value="superadmin">Superadmin</option>
                  </select>
                );
              }}
            />
          </Fields>

          <CreateForm
            title="Creating Administrator"
            message="Create a new administrator!"
            trigger="Create Administrator"
            onSubmit={admin => {
              admin.companyName = 'The View';
              return createAdmin(admin).then(() => admin);
            }}
            submitText="Create Administrator"
            validate={values => validateEmail(values)}
          />

          <UpdateForm
            title="Administrator Update Process"
            message="Update the administrator"
            trigger="Update"
            onSubmit={admin => changeAdmin(admin).then(() => admin)}
            submitText="Update"
            validate={values => validateEmailAndId(values)}
          />

          <DeleteForm
            title="Delete"
            message="Are you sure you want to delete this administrator?"
            trigger="Delete"
            onSubmit={admin => deleteAdmin(admin.id).then(() => admin)}
            submitText="Delete"
            validate={values => {
              const errors = {};
              if (!values.id) {
                errors.id = 'Please, provide id';
              }
              return errors;
            }}
          />
        </CRUDTable>
      </div>
    </Page>
  );
};

export default AdminsView;
